.App{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
}



@media only screen and (max-width: 600px) {
  .logo{
    width: 200px;
  }
}